<template>
    <div class="main">
        <h1 class="adsimple-111918003">Datenschutzerklärung</h1>

        <em v-if="lang == 'en'">Content not yet available in english.</em>

        <h2>Inhaltsverzeichnis</h2>
        <ul>
            <li>
                <a href="#einleitung-ueberblick">Einleitung und Überblick</a>
            </li>
            <li>
                <a href="#anwendungsbereich">Anwendungsbereich</a>
            </li>
            <li>
                <a href="#rechtsgrundlagen">Rechtsgrundlagen</a>
            </li>
            <li>
                <a href="#kontaktdaten-verantwortliche">Kontaktdaten des Verantwortlichen</a>
            </li>
            <li>
                <a href="#speicherdauer">Speicherdauer</a>
            </li>
            <li>
                <a href="#rechte-dsgvo">Rechte laut Datenschutz-Grundverordnung</a>
            </li>
            <li>
                <a href="#sicherheit-datenverarbeitung">Sicherheit der Datenverarbeitung</a>
            </li>
            <li>
                <a href="#kommunikation">Kommunikation</a>
            </li>
            <li>
                <a href="#webhosting-einleitung">Webhosting Einleitung</a>
            </li>
            <li>
                <a href="#messenger-kommunikation-einleitung">Messenger &amp; Kommunikation Einleitung</a>
            </li>
        </ul>

        <h2 id="einleitung-ueberblick" class="adsimple-111918003">Einleitung und Überblick</h2>
        <p>Wir haben diese Datenschutzerklärung (Fassung 14.01.2022-111918003) verfasst, um Ihnen gemäß der Vorgaben der <a class="adsimple-111918003" href="https://eur-lex.europa.eu/legal-content/DE/ALL/?uri=celex%3A32016R0679&amp;tid=111918003" target="_blank" rel="noopener">Datenschutz-Grundverordnung (EU) 2016/679</a> und anwendbaren nationalen Gesetzen zu erklären, welche personenbezogenen Daten (kurz Daten) wir als Verantwortliche &#8211; und die von uns beauftragten Auftragsverarbeiter (z. B. Provider) &#8211; verarbeiten, zukünftig verarbeiten werden und welche rechtmäßigen Möglichkeiten Sie haben. Die verwendeten Begriffe sind geschlechtsneutral zu verstehen.<br />
        <strong class="adsimple-111918003">Kurz gesagt:</strong> Wir informieren Sie umfassend über Daten, die wir über Sie verarbeiten.</p>
        <p>Datenschutzerklärungen klingen für gewöhnlich sehr technisch und verwenden juristische Fachbegriffe. Diese Datenschutzerklärung soll Ihnen hingegen die wichtigsten Dinge so einfach und transparent wie möglich beschreiben. Soweit es der Transparenz förderlich ist, werden technische <strong class="adsimple-111918003">Begriffe leserfreundlich erklärt</strong>, <strong class="adsimple-111918003">Links</strong> zu weiterführenden Informationen geboten und <strong class="adsimple-111918003">Grafiken</strong> zum Einsatz gebracht. Wir informieren damit in klarer und einfacher Sprache, dass wir im Rahmen unserer Geschäftstätigkeiten nur dann personenbezogene Daten verarbeiten, wenn eine entsprechende gesetzliche Grundlage gegeben ist. Das ist sicher nicht möglich, wenn man möglichst knappe, unklare und juristisch-technische Erklärungen abgibt, so wie sie im Internet oft Standard sind, wenn es um Datenschutz geht. Ich hoffe, Sie finden die folgenden Erläuterungen interessant und informativ und vielleicht ist die eine oder andere Information dabei, die Sie noch nicht kannten.<br />
        Wenn trotzdem Fragen bleiben, möchten wir Sie bitten, sich an die unten bzw. im Impressum genannte verantwortliche Stelle zu wenden, den vorhandenen Links zu folgen und sich weitere Informationen auf Drittseiten anzusehen. Unsere Kontaktdaten finden Sie selbstverständlich auch im Impressum.</p>

        <h2 id="anwendungsbereich" class="adsimple-111918003">Anwendungsbereich</h2>
        <p>Diese Datenschutzerklärung gilt für alle von uns im Unternehmen verarbeiteten personenbezogenen Daten und für alle personenbezogenen Daten, die von uns beauftragte Firmen (Auftragsverarbeiter) verarbeiten. Mit personenbezogenen Daten meinen wir Informationen im Sinne des Art. 4 Nr. 1 DSGVO wie zum Beispiel Name, E-Mail-Adresse und postalische Anschrift einer Person. Die Verarbeitung personenbezogener Daten sorgt dafür, dass wir unsere Dienstleistungen und Produkte anbieten und abrechnen können, sei es online oder offline. Der Anwendungsbereich dieser Datenschutzerklärung umfasst:</p>
        <ul class="adsimple-111918003">
            <li class="adsimple-111918003">alle Onlineauftritte (Websites, Onlineshops), die wir betreiben</li>
            <li class="adsimple-111918003">Social Media Auftritte und E-Mail-Kommunikation</li>
            <li class="adsimple-111918003">mobile Apps für Smartphones und andere Geräte</li>
        </ul>
        <p>
        <strong class="adsimple-111918003">Kurz gesagt:</strong> Die Datenschutzerklärung gilt für alle Bereiche, in denen personenbezogene Daten im Unternehmen über die genannten Kanäle strukturiert verarbeitet werden. Sollten wir außerhalb dieser Kanäle mit Ihnen in Rechtsbeziehungen eintreten, werden wir Sie gegebenenfalls gesondert informieren.</p>

        <h2 id="rechtsgrundlagen" class="adsimple-111918003">Rechtsgrundlagen</h2>
        <p>In der folgenden Datenschutzerklärung geben wir Ihnen transparente Informationen zu den rechtlichen Grundsätzen und Vorschriften, also den Rechtsgrundlagen der Datenschutz-Grundverordnung, die uns ermöglichen, personenbezogene Daten zu verarbeiten.<br />
        Was das EU-Recht betrifft, beziehen wir uns auf die VERORDNUNG (EU) 2016/679 DES EUROPÄISCHEN PARLAMENTS UND DES RATES vom 27. April 2016. Diese Datenschutz-Grundverordnung der EU können Sie selbstverständlich online auf EUR-Lex, dem Zugang zum EU-Recht, unter <a class="adsimple-111918003" href="https://eur-lex.europa.eu/legal-content/DE/ALL/?uri=celex%3A32016R0679&amp;tid=111918003" target="_blank" rel="noopener">https://eur-lex.europa.eu/legal-content/DE/TXT/?uri=celex%3A32016R0679</a> nachlesen.</p>
        <p>Wir verarbeiten Ihre Daten nur, wenn mindestens eine der folgenden Bedingungen zutrifft:</p>
        <ol>
            <li class="adsimple-111918003">
            <strong class="adsimple-111918003">Einwilligung</strong> (Artikel 6 Absatz 1 lit. a DSGVO): Sie haben uns Ihre Einwilligung gegeben, Daten zu einem bestimmten Zweck zu verarbeiten. Ein Beispiel wäre die Speicherung Ihrer eingegebenen Daten eines Kontaktformulars.</li>
            <li class="adsimple-111918003">
            <strong class="adsimple-111918003">Vertrag</strong> (Artikel 6 Absatz 1 lit. b DSGVO): Um einen Vertrag oder vorvertragliche Verpflichtungen mit Ihnen zu erfüllen, verarbeiten wir Ihre Daten. Wenn wir zum Beispiel einen Kaufvertrag mit Ihnen abschließen, benötigen wir vorab personenbezogene Informationen.</li>
            <li class="adsimple-111918003">
            <strong class="adsimple-111918003">Rechtliche Verpflichtung</strong> (Artikel 6 Absatz 1 lit. c DSGVO): Wenn wir einer rechtlichen Verpflichtung unterliegen, verarbeiten wir Ihre Daten. Zum Beispiel sind wir gesetzlich verpflichtet Rechnungen für die Buchhaltung aufzuheben. Diese enthalten in der Regel personenbezogene Daten.</li>
            <li class="adsimple-111918003">
            <strong class="adsimple-111918003">Berechtigte Interessen</strong> (Artikel 6 Absatz 1 lit. f DSGVO): Im Falle berechtigter Interessen, die Ihre Grundrechte nicht einschränken, behalten wir uns die Verarbeitung personenbezogener Daten vor. Wir müssen zum Beispiel gewisse Daten verarbeiten, um unsere Website sicher und wirtschaftlich effizient betreiben zu können. Diese Verarbeitung ist somit ein berechtigtes Interesse.</li>
        </ol>
        <p>Weitere Bedingungen wie die Wahrnehmung von Aufnahmen im öffentlichen Interesse und Ausübung öffentlicher Gewalt sowie dem Schutz lebenswichtiger Interessen treten bei uns in der Regel nicht auf. Soweit eine solche Rechtsgrundlage doch einschlägig sein sollte, wird diese an der entsprechenden Stelle ausgewiesen.</p>
        <p>Zusätzlich zu der EU-Verordnung gelten auch noch nationale Gesetze:</p>
        <ul class="adsimple-111918003">
            <li class="adsimple-111918003">In <strong class="adsimple-111918003">Österreich</strong> ist dies das Bundesgesetz zum Schutz natürlicher Personen bei der Verarbeitung personenbezogener Daten (<strong class="adsimple-111918003">Datenschutzgesetz</strong>), kurz <strong class="adsimple-111918003">DSG</strong>.</li>
            <li class="adsimple-111918003">In <strong class="adsimple-111918003">Deutschland</strong> gilt das <strong class="adsimple-111918003">Bundesdatenschutzgesetz</strong>, kurz<strong class="adsimple-111918003"> BDSG</strong>.</li>
        </ul>
        <p>Sofern weitere regionale oder nationale Gesetze zur Anwendung kommen, informieren wir Sie in den folgenden Abschnitten darüber.</p>

        <h2 id="kontaktdaten-verantwortliche" class="adsimple-111918003">Kontaktdaten des Verantwortlichen</h2>
        <p>Sollten Sie Fragen zum Datenschutz haben, finden Sie nachfolgend die Kontaktdaten der verantwortlichen Person bzw. Stelle:<br />
        <span class="adsimple-111918003" style="font-weight: 400">Alois Ecker</span>
        </p>
        <p>E-Mail: <a href="mailto:alois.ecker@uni-graz.at">alois.ecker@uni-graz.at</a>
        </p>
        <p>Impressum: <a href="https://geschichtsdidaktik.eu/impressum/">https://geschichtsdidaktik.eu/impressum/</a>
        </p>

        <h2 id="speicherdauer" class="adsimple-111918003">Speicherdauer</h2>
        <p>Dass wir personenbezogene Daten nur so lange speichern, wie es für die Bereitstellung unserer Dienstleistungen und Produkte unbedingt notwendig ist, gilt als generelles Kriterium bei uns. Das bedeutet, dass wir personenbezogene Daten löschen, sobald der Grund für die Datenverarbeitung nicht mehr vorhanden ist. In einigen Fällen sind wir gesetzlich dazu verpflichtet, bestimmte Daten auch nach Wegfall des ursprüngliches Zwecks zu speichern, zum Beispiel zu Zwecken der Buchführung.</p>
        <p>Sollten Sie die Löschung Ihrer Daten wünschen oder die Einwilligung zur Datenverarbeitung widerrufen, werden die Daten so rasch wie möglich und soweit keine Pflicht zur Speicherung besteht, gelöscht.</p>
        <p>Über die konkrete Dauer der jeweiligen Datenverarbeitung informieren wir Sie weiter unten, sofern wir weitere Informationen dazu haben.</p>

        <h2 id="rechte-dsgvo" class="adsimple-111918003">Rechte laut Datenschutz-Grundverordnung</h2>
        <p>Laut Artikel 13 DSGVO stehen Ihnen die folgenden Rechte zu, damit es zu einer fairen und transparenten Verarbeitung von Daten kommt:</p>
        <ul class="adsimple-111918003">
            <li class="adsimple-111918003">Sie haben laut Artikel 15 DSGVO ein Auskunftsrecht darüber, ob wir Daten von Ihnen verarbeiten. Sollte das zutreffen, haben Sie Recht darauf eine Kopie der Daten zu erhalten und die folgenden Informationen zu erfahren:
                <ul class="adsimple-111918003">
                    <li class="adsimple-111918003">zu welchem Zweck wir die Verarbeitung durchführen;</li>
                    <li class="adsimple-111918003">die Kategorien, also die Arten von Daten, die verarbeitet werden;</li>
                    <li class="adsimple-111918003">wer diese Daten erhält und wenn die Daten an Drittländer übermittelt werden, wie die Sicherheit garantiert werden kann;</li>
                    <li class="adsimple-111918003">wie lange die Daten gespeichert werden;</li>
                    <li class="adsimple-111918003">das Bestehen des Rechts auf Berichtigung, Löschung oder Einschränkung der Verarbeitung und dem Widerspruchsrecht gegen die Verarbeitung;</li>
                    <li class="adsimple-111918003">dass Sie sich bei einer Aufsichtsbehörde beschweren können (Links zu diesen Behörden finden Sie weiter unten);</li>
                    <li class="adsimple-111918003">die Herkunft der Daten, wenn wir sie nicht bei Ihnen erhoben haben;</li>
                    <li class="adsimple-111918003">ob Profiling durchgeführt wird, ob also Daten automatisch ausgewertet werden, um zu einem persönlichen Profil von Ihnen zu gelangen.</li>
                </ul>
            </li>
            <li class="adsimple-111918003">Sie haben laut Artikel 16 DSGVO ein Recht auf Berichtigung der Daten, was bedeutet, dass wir Daten richtig stellen müssen, falls Sie Fehler finden.</li>
            <li class="adsimple-111918003">Sie haben laut Artikel 17 DSGVO das Recht auf Löschung („Recht auf Vergessenwerden“), was konkret bedeutet, dass Sie die Löschung Ihrer Daten verlangen dürfen.</li>
            <li class="adsimple-111918003">Sie haben laut Artikel 18 DSGVO das Recht auf Einschränkung der Verarbeitung, was bedeutet, dass wir die Daten nur mehr speichern dürfen aber nicht weiter verwenden.</li>
            <li class="adsimple-111918003">Sie haben laut Artikel 19 DSGVO das Recht auf Datenübertragbarkeit, was bedeutet, dass wir Ihnen auf Anfrage Ihre Daten in einem gängigen Format zur Verfügung stellen.</li>
            <li class="adsimple-111918003">Sie haben laut Artikel 21 DSGVO ein Widerspruchsrecht, welches nach Durchsetzung eine Änderung der Verarbeitung mit sich bringt.
                <ul class="adsimple-111918003">
                    <li class="adsimple-111918003">Wenn die Verarbeitung Ihrer Daten auf Artikel 6 Abs. 1 lit. e (öffentliches Interesse, Ausübung öffentlicher Gewalt) oder Artikel 6 Abs. 1 lit. f (berechtigtes Interesse) basiert, können Sie gegen die Verarbeitung Widerspruch einlegen. Wir prüfen danach so rasch wie möglich, ob wir diesem Widerspruch rechtlich nachkommen können.</li>
                    <li class="adsimple-111918003">Werden Daten verwendet, um Direktwerbung zu betreiben, können Sie jederzeit gegen diese Art der Datenverarbeitung widersprechen. Wir dürfen Ihre Daten danach nicht mehr für Direktmarketing verwenden.</li>
                    <li class="adsimple-111918003">Werden Daten verwendet, um Profiling zu betreiben, können Sie jederzeit gegen diese Art der Datenverarbeitung widersprechen. Wir dürfen Ihre Daten danach nicht mehr für Profiling verwenden.</li>
                </ul>
            </li>
            <li class="adsimple-111918003">Sie haben laut Artikel 22 DSGVO unter Umständen das Recht, nicht einer ausschließlich auf einer automatisierten Verarbeitung (zum Beispiel Profiling) beruhenden Entscheidung unterworfen zu werden.</li>
        </ul>
        <p>
        <strong class="adsimple-111918003">Kurz gesagt:</strong> Sie haben Rechte &#8211; zögern Sie nicht, die oben gelistete verantwortliche Stelle bei uns zu kontaktieren!</p>
        <p>Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das Datenschutzrecht verstößt oder Ihre datenschutzrechtlichen Ansprüche in sonst einer Weise verletzt worden sind, können Sie sich bei der Aufsichtsbehörde beschweren. Diese ist für Österreich die Datenschutzbehörde, deren Website Sie unter <a class="adsimple-111918003" href="https://www.dsb.gv.at/?tid=111918003" target="_blank" rel="noopener">https://www.dsb.gv.at/</a> finden. In Deutschland gibt es für jedes Bundesland einen Datenschutzbeauftragten. Für nähere Informationen können Sie sich an die <a class="adsimple-111918003" href="https://www.bfdi.bund.de" target="_blank" rel="noopener">Bundesbeauftragte für den Datenschutz und die Informationsfreiheit (BfDI)</a> wenden. Für unser Unternehmen ist die folgende lokale Datenschutzbehörde zuständig:</p>

        <h2 id="oesterreich-datenschutzbehoerde" class="adsimple-111918003">Österreich Datenschutzbehörde</h2>
        <p>
        <strong class="adsimple-111918003">Leiterin: </strong>Mag. Dr. Andrea Jelinek<strong class="adsimple-111918003">
        <br />
        Adresse: </strong>Barichgasse 40-42, 1030 Wien<strong class="adsimple-111918003">
        <br />
        Telefonnr.: </strong>+43 1 52 152-0<strong class="adsimple-111918003">
        <br />
        E-Mail-Adresse: </strong>
        <a class="adsimple-111918003" href="mailto:dsb@dsb.gv.at" target="_blank" rel="noopener">dsb@dsb.gv.at</a>
        <strong class="adsimple-111918003">
        <br />
        Website: </strong>
        <a class="adsimple-111918003" href="https://www.dsb.gv.at/" target="_blank" rel="noopener">https://www.dsb.gv.at/</a>
        </p>

        <h2 id="sicherheit-datenverarbeitung" class="adsimple-111918003">Sicherheit der Datenverarbeitung</h2>
        <p>Um personenbezogene Daten zu schützen, haben wir sowohl technische als auch organisatorische Maßnahmen umgesetzt. Wo es uns möglich ist, verschlüsseln oder pseudonymisieren wir personenbezogene Daten. Dadurch machen wir es im Rahmen unserer Möglichkeiten so schwer wie möglich, dass Dritte aus unseren Daten auf persönliche Informationen schließen können.</p>
        <p>Art. 25 DSGVO spricht hier von &#8220;Datenschutz durch Technikgestaltung und durch datenschutzfreundliche Voreinstellungen&#8221; und meint damit, dass man sowohl bei Software (z. B. Formularen) also auch Hardware (z. B. Zugang zum Serverraum) immer an Sicherheit denkt und entsprechende Maßnahmen setzt. Im Folgenden gehen wir, falls erforderlich, noch auf konkrete Maßnahmen ein.</p>

        <h2 id="tls-verschluesselung-https" class="adsimple-111918003">TLS-Verschlüsselung mit https</h2>
        <p>TLS, Verschlüsselung und https klingen sehr technisch und sind es auch. Wir verwenden HTTPS (das Hypertext Transfer Protocol Secure steht für „sicheres Hypertext-Übertragungsprotokoll“), um Daten abhörsicher im Internet zu übertragen.<br />
        Das bedeutet, dass die komplette Übertragung aller Daten von Ihrem Browser zu unserem Webserver abgesichert ist &#8211; niemand kann &#8220;mithören&#8221;.</p>
        <p>Damit haben wir eine zusätzliche Sicherheitsschicht eingeführt und erfüllen Datenschutz durch Technikgestaltung <a class="adsimple-111918003" href="https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32016R0679&amp;from=DE&amp;tid=111918003" target="_blank" rel="noopener">Artikel 25 Absatz 1 DSGVO</a>). Durch den Einsatz von TLS (Transport Layer Security), einem Verschlüsselungsprotokoll zur sicheren Datenübertragung im Internet, können wir den Schutz vertraulicher Daten sicherstellen.<br />
        Sie erkennen die Benutzung dieser Absicherung der Datenübertragung am kleinen Schlosssymbol <img role="img" src="https://www.adsimple.at/wp-content/uploads/2018/03/schlosssymbol-https.svg" width="17" height="18" /> links oben im Browser, links von der Internetadresse (z. B. beispielseite.de) und der Verwendung des Schemas https (anstatt http) als Teil unserer Internetadresse.<br />
        Wenn Sie mehr zum Thema Verschlüsselung wissen möchten, empfehlen wir die Google Suche nach &#8220;Hypertext Transfer Protocol Secure wiki&#8221; um gute Links zu weiterführenden Informationen zu erhalten.</p>

        <h2 id="kommunikation" class="adsimple-111918003">Kommunikation</h2>
        <table border="1" cellpadding="15">
        <tbody>
        <tr>
        <td>
        <strong class="adsimple-111918003">Kommunikation Zusammenfassung</strong>
        <br />
        &#x1f465; Betroffene: Alle, die mit uns per Telefon, E-Mail oder Online-Formular kommunizieren<br />
        &#x1f4d3; Verarbeitete Daten: z. B. Telefonnummer, Name, E-Mail-Adresse, eingegebene Formulardaten. Mehr Details dazu finden Sie bei der jeweils eingesetzten Kontaktart<br />
        &#x1f91d; Zweck: Abwicklung der Kommunikation mit Kunden, Geschäftspartnern usw.<br />
        &#x1f4c5; Speicherdauer: Dauer des Geschäftsfalls und der gesetzlichen Vorschriften<br />
        &#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. b DSGVO (Vertrag), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</td>
        </tr>
        </tbody>
        </table>
        <p>Wenn Sie mit uns Kontakt aufnehmen und per Telefon, E-Mail oder Online-Formular kommunizieren, kann es zur Verarbeitung personenbezogener Daten kommen.</p>
        <p>Die Daten werden für die Abwicklung und Bearbeitung Ihrer Frage und des damit zusammenhängenden Geschäftsvorgangs verarbeitet. Die Daten während ebensolange gespeichert bzw. so lange es das Gesetz vorschreibt.</p>
        <h3 class="adsimple-111918003">Betroffene Personen</h3>
        <p>Von den genannten Vorgängen sind alle betroffen, die über die von uns bereit gestellten Kommunikationswege den Kontakt zu uns suchen.</p>
        <h3 class="adsimple-111918003">Telefon</h3>
        <p>Wenn Sie uns anrufen, werden die Anrufdaten auf dem jeweiligen Endgerät und beim eingesetzten Telekommunikationsanbieter pseudonymisiert gespeichert. Außerdem können Daten wie Name und Telefonnummer im Anschluss per E-Mail versendet und zur Anfragebeantwortung gespeichert werden. Die Daten werden gelöscht, sobald der Geschäftsfall beendet wurde und es gesetzliche Vorgaben erlauben.</p>

        <h3 class="adsimple-111918003">E-Mail</h3>
        <p>Wenn Sie mit uns per E-Mail kommunizieren, werden Daten gegebenenfalls auf dem jeweiligen Endgerät (Computer, Laptop, Smartphone,&#8230;) gespeichert und es kommt zur Speicherung von Daten auf dem E-Mail-Server. Die Daten werden gelöscht, sobald der Geschäftsfall beendet wurde und es gesetzliche Vorgaben erlauben.</p>
        <h3 class="adsimple-111918003">Online Formulare</h3>
        <p>Wenn Sie mit uns mittels Online-Formular kommunizieren, werden Daten auf unserem Webserver gespeichert und gegebenenfalls an eine E-Mail-Adresse von uns weitergeleitet. Die Daten werden gelöscht, sobald der Geschäftsfall beendet wurde und es gesetzliche Vorgaben erlauben.</p>

        <h3 class="adsimple-111918003">Rechtsgrundlagen</h3>
        <p>Die Verarbeitung der Daten basiert auf den folgenden Rechtsgrundlagen:</p>
        <ul class="adsimple-111918003">
            <li class="adsimple-111918003">Art. 6 Abs. 1 lit. a DSGVO (Einwilligung): Sie geben uns die Einwilligung Ihre Daten zu speichern und weiter für den Geschäftsfall betreffende Zwecke zu verwenden;</li>
            <li class="adsimple-111918003">Art. 6 Abs. 1 lit. b DSGVO (Vertrag): Es besteht die Notwendigkeit für die Erfüllung eines Vertrags mit Ihnen oder einem Auftragsverarbeiter wie z. B. dem Telefonanbieter oder wir müssen die Daten für vorvertragliche Tätigkeiten, wie z. B. die Vorbereitung eines Angebots, verarbeiten;</li>
            <li class="adsimple-111918003">Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen): Wir wollen Kundenanfragen und geschäftliche Kommunikation in einem professionellen Rahmen betreiben. Dazu sind gewisse technische Einrichtungen wie z. B. E-Mail-Programme, Exchange-Server und Mobilfunkbetreiber notwendig, um die Kommunikation effizient betreiben zu können.</li>
        </ul>

        <h2 id="webhosting-einleitung" class="adsimple-111918003">Webhosting Einleitung</h2>
        <table border="1" cellpadding="15">
            <tbody>
                <tr>
                <td>
                <strong class="adsimple-111918003">Webhosting Zusammenfassung</strong>
                <br />
                &#x1f465; Betroffene: Besucher der Website<br />
                &#x1f91d; Zweck: professionelles Hosting der Website und Absicherung des Betriebs<br />
                &#x1f4d3; Verarbeitete Daten: IP-Adresse, Zeitpunkt des Websitebesuchs, verwendeter Browser und weitere Daten. Mehr Details dazu finden Sie weiter unten bzw. beim jeweils eingesetzten Webhosting Provider.<br />
                &#x1f4c5; Speicherdauer: abhängig vom jeweiligen Provider, aber in der Regel 2 Wochen<br />
                &#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit.f DSGVO (Berechtigte Interessen)</td>
                </tr>
            </tbody>
        </table>

        <h3 class="adsimple-111918003">Was ist Webhosting?</h3>
        <p>Wenn Sie heutzutage Websites besuchen, werden gewisse Informationen &#8211; auch personenbezogene Daten &#8211; automatisch erstellt und gespeichert, so auch auf dieser Website. Diese Daten sollten möglichst sparsam und nur mit Begründung verarbeitet werden. Mit Website meinen wir übrigens die Gesamtheit aller Webseiten auf einer Domain, d.h. alles von der Startseite (Homepage) bis hin zur aller letzten Unterseite (wie dieser hier). Mit Domain meinen wir zum Beispiel beispiel.de oder musterbeispiel.com.</p>
        <p>Wenn Sie eine Website auf einem Bildschirm ansehen möchten, verwenden Sie dafür ein Programm, das sich Webbrowser nennt. Sie kennen vermutlich einige Webbrowser beim Namen: Google Chrome, Microsoft Edge, Mozilla Firefox und Apple Safari.</p>
        <p>Dieser Webbrowser muss sich zu einem anderen Computer verbinden, wo der Code der Website gespeichert ist: dem Webserver. Der Betrieb eines Webservers ist eine komplizierte und aufwendige Aufgabe, weswegen dies in der Regel von professionellen Anbietern, den Providern, übernommen wird. Diese bieten Webhosting an und sorgen damit für eine verlässliche und fehlerfreie Speicherung der Daten von Websites.</p>
        <p>Bei der Verbindungsaufnahme des Browsers auf Ihrem Computer (Desktop, Laptop, Smartphone) und während der Datenübertragung zu und vom Webserver kann es zu einer Verarbeitung personenbezogener Daten kommen. Einerseits speichert Ihr Computer Daten, andererseits muss auch der Webserver Daten eine Zeit lang speichern, um einen ordentlichen Betrieb zu gewährleisten.</p>
        <p>Zur Veranschaulichung:</p>
        <p>
        <img role="img" src="https://www.adsimple.at/wp-content/uploads/2018/03/browser-und-webserver.svg" alt="Browser und Webserver" width="100%" />
        </p>

        <h3 class="adsimple-111918003">Warum verarbeiten wir personenbezogene Daten?</h3>
        <p>Die Zwecke der Datenverarbeitung sind:</p>
        <ol>
            <li class="adsimple-111918003">Professionelles Hosting der Website und Absicherung des Betriebs</li>
            <li class="adsimple-111918003">zur Aufrechterhaltung der Betriebs- und IT-Sicherheit</li>
            <li class="adsimple-111918003">Anonyme Auswertung des Zugriffsverhaltens zur Verbesserung unseres Angebots und ggf. zur Strafverfolgung bzw. Verfolgung von Ansprüchen</li>
        </ol>

        <h3 class="adsimple-111918003">Welche Daten werden verarbeitet?</h3>
        <p>Auch während Sie unsere Website jetzt gerade besuchen, speichert unser Webserver, das ist der Computer auf dem diese Webseite gespeichert ist, in der Regel automatisch Daten wie</p>
        <ul class="adsimple-111918003">
            <li class="adsimple-111918003">die komplette Internetadresse (URL) der aufgerufenen Webseite (z. B. https://www.beispielwebsite.de/beispielunterseite.html?tid=111918003)</li>
            <li class="adsimple-111918003">Browser und Browserversion (z. B. Chrome 87)</li>
            <li class="adsimple-111918003">das verwendete Betriebssystem (z. B. Windows 10)</li>
            <li class="adsimple-111918003">die Adresse (URL) der zuvor besuchten Seite (Referrer URL) (z. B. https://www.beispielquellsite.de/vondabinichgekommen.html/)</li>
            <li class="adsimple-111918003">den Hostnamen und die IP-Adresse des Geräts von welchem aus zugegriffen wird (z. B. COMPUTERNAME und 194.23.43.121)</li>
            <li class="adsimple-111918003">Datum und Uhrzeit</li>
            <li class="adsimple-111918003">in Dateien, den sogenannten Webserver-Logfiles</li>
        </ul>

        <h3 class="adsimple-111918003">Wie lange werden Daten gespeichert?</h3>
        <p>In der Regel werden die oben genannten Daten zwei Wochen gespeichert und danach automatisch gelöscht. Wir geben diese Daten nicht weiter, können jedoch nicht ausschließen, dass diese Daten beim Vorliegen von rechtswidrigem Verhalten von Behörden eingesehen werden.</p>
        <p>
        <strong class="adsimple-111918003">Kurz gesagt:</strong> Ihr Besuch wird durch unseren Provider (Firma, die unsere Website auf speziellen Computern (Servern) laufen lässt), protokolliert, aber wir geben Ihre Daten nicht ohne Zustimmung weiter!</p>

        <h3 class="adsimple-111918003">Rechtsgrundlage</h3>
        <p>Die Rechtmäßigkeit der Verarbeitung personenbezogener Daten im Rahmen des Webhosting ergibt sich aus Art. 6 Abs. 1 lit. f DSGVO (Wahrung der berechtigten Interessen), denn die Nutzung von professionellem Hosting bei einem Provider ist notwendig, um das Unternehmen im Internet sicher und nutzerfreundlich präsentieren und Angriffe und Forderungen hieraus gegebenenfalls verfolgen zu können.</p>
        <p>Zwischen uns und dem Hostingprovider besteht in der Regel ein Vertrag über die Auftragsverarbeitung gemäß Art. 28 f. DSGVO, der die Einhaltung von Datenschutz gewährleistet und Datensicherheit garantiert.</p>

        <h2 id="messenger-kommunikation-einleitung" class="adsimple-111918003">Messenger &amp; Kommunikation Einleitung</h2>
        <table border="1" cellpadding="15">
            <tbody>
                <tr>
                <td>
                <strong class="adsimple-111918003">Messenger &amp; Kommunikation Datenschutzerklärung Zusammenfassung</strong>
                <br />
                &#x1f465; Betroffene: Besucher der Website<br />
                &#x1f91d; Zweck: Kontaktanfragen und die allgemeine Kommunikation zwischen uns und Ihnen<br />
                &#x1f4d3; Verarbeitete Daten: Daten wie etwa Name, Adresse, E-Mailadresse, Telefonnummer, allgemeine Inhaltsdaten, gegebenenfalls IP-Adresse<br />
                Mehr Details dazu finden Sie bei den jeweils eingesetzten Tools.<br />
                &#x1f4c5; Speicherdauer: abhängig von den verwendeten Messenger- &amp; Kommunikationsfunktionen<br />
                &#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen), Art. 6 Abs. 1 S. 1 lit. b. DSGVO (vertragliche oder vorvertragliche Verpflichtungen)</td>
                </tr>
            </tbody>
        </table>

        <h3 class="adsimple-111918003">Was sind Messenger- &amp; Kommunikationsfunktionen?</h3>
        <p>Wir bieten auf unserer Website verschiedene Möglichkeiten (etwa Messenger- und Chatfunktionen, Online- bzw. Kontaktformulare, E-Mail, Telefon), um mit uns zu kommunizieren. Dabei werden auch Ihre Daten, soweit es für die Beantwortung Ihrer Anfrage und unseren darauffolgenden Maßnahmen nötig ist, verarbeitet und gespeichert.</p>
        <p>Wir nutzen neben klassischen Kommunikationsmitteln wie E-Mail, Kontaktformularen oder Telefon auch Chats bzw. Messenger. Die derzeit am häufigsten verwendete Messenger-Funktion ist WhatsApp, aber es gibt natürlich speziell für Websites viele verschiedene Anbieter, die Messenger-Funktionen anbieten. Wenn Inhalte Ende zu Ende verschlüsselt sind, wird darauf in den einzelnen Datenschutztexten oder in der Datenschutzerklärung des jeweiligen Anbieters hingewiesen. Eine Ende-zu-Ende-Verschlüsselung bedeutet nichts anders, als dass Inhalte einer Nachricht selbst für den Anbieter nicht sichtbar sind. Allerdings können trotzdem Informationen zu Ihrem Gerät, Standorteinstellungen und andere technische Daten verarbeitet und gespeichert werden.</p>

        <h3 class="adsimple-111918003">Warum nutzen wir Messenger- &amp; Kommunikationsfunktionen?</h3>
        <p>Kommunikationsmöglichkeiten mit Ihnen sind für uns von großer Bedeutung. Schließlich wollen wir mit Ihnen sprechen und alle möglichen Fragen zu unserem Service bestmöglich beantworten. Eine gut funktionierende Kommunikation ist bei uns ein wichtiger Teil unserer Dienstleistung. Mit den praktischen Messenger- &amp; Kommunikationsfunktionen können Sie jederzeit jene wählen, die Ihnen am liebsten sind. In Ausnahmefällen kann es aber auch vorkommen, dass wir bestimmte Fragen über Chat bzw. Messenger nicht beantworten. Das ist der Fall, wenn es etwa um interne vertragliche Angelegenheiten geht. Hier empfehlen wir andere Kommunikationsmöglichkeiten wie E-Mail oder Telefon.</p>
        <p>Wir gehen in der Regel davon aus, dass wir datenschutzrechtlich verantwortlich bleiben, auch wenn wir Dienste einer Social-Media-Plattform nutzen. Der Europäische Gerichtshof hat jedoch entschieden, dass in bestimmten Fällen der Betreiber der Social-Media-Plattform zusammen mit uns gemeinsam verantwortlich im Sinne des Art. 26 DSGVO sein kann. Soweit dies der Fall ist, weisen wir gesondert darauf hin und arbeiten auf Grundlage einer diesbezüglichen Vereinbarung. Das Wesentliche der Vereinbarung ist weiter unten bei der betroffenen Plattform wiedergegeben.</p>
        <p>Bitte beachten Sie, dass bei der Nutzung unserer eingebauten Elemente auch Daten von Ihnen außerhalb der Europäischen Union verarbeitet werden können, da viele Anbieter, beispielsweise Facebook-Messenger oder WhatsApp amerikanische Unternehmen sind. Dadurch können Sie möglicherweise Ihre Rechte in Bezug auf Ihre personenbezogenen Daten nicht mehr so leicht einfordern bzw. durchsetzen.</p>

        <h3 class="adsimple-111918003">Welche Daten werden verarbeitet?</h3>
        <p>Welche Daten genau gespeichert und verarbeitet werden, hängt vom jeweiligen Anbieter der Messenger- &amp; Kommunikationsfunktionen ab. Grundsätzlich handelt es sich um Daten wie etwa Name, Adresse, Telefonnummer, E-Mailadresse und Inhaltsdaten wie beispielsweise alle Informationen, die Sie in ein Kontaktformular eingeben. Meistens werden auch Informationen zu Ihrem Gerät und die IP-Adresse gespeichert. Daten, die über eine Messenger- &amp; Kommunikationsfunktion erhoben werden, werden auch auf den Servern der Anbieter gespeichert.</p>
        <p>Wenn Sie genau wissen wollen, welche Daten bei den jeweiligen Anbietern gespeichert und verarbeitet werden und wie Sie der Datenverarbeitung widersprechen können, sollten Sie die jeweilige Datenschutzerklärung des Unternehmens sorgfältig durchlesen.</p>

        <h3 class="adsimple-111918003">Wie lange werden Daten gespeichert?</h3>
        <p>Wie lange die Daten verarbeitet und gespeichert werden, hängt in erster Linie von unseren verwendeten Tools ab. Weiter unten erfahren Sie mehr über die Datenverarbeitung der einzelnen Tools. In den Datenschutzerklärungen der Anbieter steht üblicherweise genau, welche Daten wie lange gespeichert und verarbeitet werden. Grundsätzlich werden personenbezogene Daten nur so lange verarbeitet, wie es für die Bereitstellung unserer Dienste nötig ist. Wenn Daten in Cookies gespeichert werden, variiert die Speicherdauer stark. Die Daten können gleich nach dem Verlassen einer Website wieder gelöscht werden, sie können aber auch über mehrere Jahre gespeichert bleiben. Daher sollten Sie sich jedes einzelnen Cookie im Detail ansehen, wenn Sie über die Datenspeicherung Genaueres wissen wollen. Meistens finden Sie in den Datenschutzerklärungen der einzelnen Anbieter auch aufschlussreiche Informationen über die einzelnen Cookies.</p>

        <h3 class="adsimple-111918003">Widerspruchsrecht</h3>
        <p>Sie haben auch jederzeit das Recht und die Möglichkeit Ihre Einwilligung zur Verwendung von Cookies bzw. Drittanbietern zu widerrufen. Das funktioniert entweder über unser Cookie-Management-Tool oder über andere Opt-Out-Funktionen. Zum Bespiel können Sie auch die Datenerfassung durch Cookies verhindern, indem Sie in Ihrem Browser die Cookies verwalten, deaktivieren oder löschen. Für weitere Informationen verweisen wir auf den Abschnitt zur Einwilligung.</p>
        <p>Da bei Messenger- &amp; Kommunikationsfunktionen Cookies zum Einsatz kommen können, empfehlen wir Ihnen auch unsere allgemeine Datenschutzerklärung über Cookies. Um zu erfahren, welche Daten von Ihnen genau gespeichert und verarbeitet werden, sollten Sie die Datenschutzerklärungen der jeweiligen Tools durchlesen.</p>
        
        <h3 class="adsimple-111918003">Rechtsgrundlage</h3>
        <p>Wenn Sie eingewilligt haben, dass Daten von Ihnen durch eingebundene Messenger- &amp; Kommunikationsfunktionen verarbeitet und gespeichert werden können, gilt diese Einwilligung als Rechtsgrundlage der Datenverarbeitung <strong class="adsimple-111918003">(Art. 6 Abs. 1 lit. a DSGVO)</strong>. Wir bearbeiten Ihre Anfrage und verwalten Ihre Daten im Rahmen vertraglicher oder vorvertraglicher Beziehungen, um unsere vorvertraglichen und vertraglichen Pflichten zu erfüllen bzw. Anfragen zu beantworten. Grundlage dafür ist <strong class="adsimple-111918003">Art. 6 Abs. 1 S. 1 lit. b. DSGVO</strong>. Grundsätzlich werden Ihre Daten bei Vorliegen einer Einwilligung auch auf Grundlage unseres berechtigten Interesses <strong class="adsimple-111918003">(Art. 6 Abs. 1 lit. f DSGVO)</strong> an einer schnellen und guten Kommunikation mit Ihnen oder anderen Kunden und Geschäftspartnern gespeichert und verarbeitet.</p>
        <p>Alle Texte sind urheberrechtlich geschützt.</p>
        <p style="margin-top:15px">Quelle: Erstellt mit dem <a href="https://www.adsimple.at/datenschutz-generator/" title="Datenschutz Generator von AdSimple für Österreich">Datenschutz Generator</a> von AdSimple</p>

    </div>
</template>

<script>
export default {
    data() {
        const lang = localStorage.getItem('lang') || 'en';

        return {
            lang: lang,
        }
    },
}
</script>

<style lang="scss" scoped>

a { 
    color: var(--dark_grey);
}

a:hover {
    text-decoration: underline;
}

h1 {
    font-size: 3.5rem;
    line-height: 1.1;
}

h2 {
    font-size: 2.5rem;
    line-height: 1.1;

    margin-top: 2rem;
    margin-bottom: 1rem;
}

h3 {
    font-size: 2rem;
    line-height: 1.2;

    margin-top: 1.5rem;
    margin-bottom: 0.5rem;
}

ol, 
ul {
    margin-left: 1rem;

    a {
        font-size: 1.2rem;
    }
}

p + ol,
p + ul {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

table td {
    padding: 0.8rem;
}

.main {
    max-width: 800px;
    
    margin-left: auto;
    margin-right: auto;

    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
    white-space: normal;
}

</style>